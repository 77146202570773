@import '../../../../../variables.scss';

.project-item {
  margin: 10px 0px;
  display: flex;
  align-items: center;
  padding: 10px 0px;
  border-bottom: thin solid $gray;
  .item {
    text-align: left;
    flex: 1 1 0px;
    &:last-child {
      text-align: right;
    }
    &.name {
      font-weight: bold;
      cursor: pointer;
    }
  }
  &:last-child {
    border-bottom: none;
  }
}
