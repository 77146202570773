@import '../../../variables.scss';

.user-management {
  padding-top: 15px;
  .headline {
    font-size: $text-large;
    font-weight: bold;
    margin-bottom: 15px;
  }
  .create-wrapper {
    margin-bottom: 15px;
  }
}
