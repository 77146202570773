@import '../../../variables.scss';

#login {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 80px);

  .form-wrapper {
    width: 25vw;
    min-width: 350px;
    box-shadow: $default-box-shadow;
    border-radius: $default-border-radius;
    .headline {
      text-align: center;
      font-size: $text-x-large;
      color: $black;
      padding: 30px 0px;
      border-bottom: 2px solid $gray;
      font-family: 'Stolzl-Bold';
    }
    form {
      padding: 40px;
      .element-wrapper,
      .error {
        margin-bottom: 20px;
      }
      .switches {
        display: flex;
        .switch {
          flex: 1 1 0px;
          &:last-child {
            text-align: right;
          }
        }
      }
    }
  }

  @media (max-width: $breakpoint) {
    .form-wrapper {
      width: 100%;
      margin: 10px;
    }
  }
}
