@import '../../../../variables.scss';

#userList {
  margin: auto;
  margin-top: 10px;
  .listItem {
    margin: auto;
    margin-top: 20px;
    padding: 10px;
    border-radius: $default-border-radius;
    background-color: $black;
    cursor: pointer;
    * {
      color: $white;
    }
  }
}
