@import '../../../variables.scss';
#timelist {
  margin: auto;
  margin-top: 10px;

  .headline {
    margin-top: 15px;
    font-size: $text-large;
    font-weight: bold;
    margin-bottom: 15px;
  }

  .list {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    .listItem {
      min-width: 40%;
      flex: 1 1 0px;
      padding: 10px;
      border-radius: $default-border-radius;
      background-color: $black;
      display: flex;
      flex-wrap: wrap;
      * {
        color: $white;
      }
      .main,
      .meta {
        flex: 1 1 0px;
        text-align: left;
      }
      .meta {
        text-align: right;
      }
      .tasks-wrapper {
        width: 100%;
        textarea {
          color: $white;
          opacity: 1;
          width: 100%;
          resize: none;
          height: auto;
          background-color: transparent;
          border: none;
        }
      }
    }
  }

  @media (max-width: $breakpoint) {
    .list {
      display: block;
      margin-top: 10px;
      .listItem {
        margin-top: 10px;
        display: block;
        .main,
        .meta {
          width: 100%;
          text-align: left;
        }
      }
    }
  }
}
