@import '../../variables.scss';
#home {
  .headline {
    margin-top: 15px;
    font-size: $text-large;
    font-weight: bold;
    margin-bottom: 15px;
  }
  .navigator {
    display: flex;
    gap: 30px;
    margin: auto;
    .timeWrapper {
      flex: 1 1 0px;
      display: flex;
      align-items: center;
      gap: 10px;
      input {
        text-align: center;
        font-size: 20px;
      }
      svg {
        cursor: pointer;
      }
    }
    .btnWrapper {
      flex: 1 1 0px;

      .btnAction {
        * {
          color: $white;
        }
        height: 55px;
        display: flex;
        margin: auto;
        padding: 5px;
        border-radius: 5px;
        background-color: $black;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
  }
  @media (max-width: $breakpoint) {
    .navigator {
      display: block;
      .btnWrapper {
        margin-top: 10px;
        .btnAction {
          height: auto;
        }
      }
    }
  }
}
