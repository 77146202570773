@import './variables';
@import '~bootstrap/scss/bootstrap';

@font-face {
  font-family: 'Solzl-Book';
  src: local('Solzl-Book'), url(./assets/font/Solzl/Stolzl-Book.ttf) format('truetype');
}
@font-face {
  font-family: 'Stolzl-Bold';
  src: local('Stolzl-Bold'), url(./assets/font/Solzl/Stolzl-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'Stolzl-Light';
  src: local('Stolzl-Light'), url(./assets/font/Solzl/Stolzl-Light.ttf) format('truetype');
}
@font-face {
  font-family: 'Stolzl-Medium';
  src: local('Stolzl-Medium'), url(./assets/font/Solzl/Stolzl-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'Stolzl-Bold';
  src: local('Stolzl-Regular'), url(./assets/font/Solzl/Stolzl-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Stolzl-Thin';
  src: local('Stolzl-Thin'), url(./assets/font/Solzl/Stolzl-Thin.ttf) format('truetype');
}

@font-face {
  font-family: 'Orbitron-Bold';
  src: local('Orbitron-Bold'), url(./assets/font/Orbitron/Orbitron-Bold.ttf) format('truetype');
}

* {
  padding: 0px;
  margin: 0px;
  font-family: 'Solzl-Book', sans-serif;
  font-size: $text-medium;
  color: $black;
}

body {
  font-family: 'Solzl-Book', sans-serif;
}
