@import '../../../variables';

.custom-MUI-checkbox {
  .MuiCheckbox-colorPrimary {
    &.Mui-checked {
      .MuiSvgIcon-root * {
        color: $black;
      }
    }
  }
}
