.member-list {
  .user-list {
    .user {
      display: flex;
      gap: 10px;
      justify-items: center;
      align-items: center;
    }
  }
}
