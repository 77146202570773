@import '../../../variables.scss';

#export {
  padding-top: 15px;
  .headline {
    font-size: $text-large;
    font-weight: bold;
    margin-bottom: 15px;
  }
  .timespan {
    display: flex;
    gap: 10px;
    .item {
      flex: 1 1 0px;
    }
  }
  .project-selection,
  .user-selection,
  .start-button {
    margin-top: 10px;
  }
  .history {
    margin: 25px 0px;
    .entry {
      margin: 5px 0px;
      padding: 5px 0px;
      border-top: thin solid $black;
      &:first-child {
        border-top: none;
      }
      display: flex;
      flex-wrap: wrap;
      .createdOn,
      .timespan,
      .user,
      .project {
        min-width: 40%;
      }
      .re-export-wrapper {
        width: 100%;
        text-align: right;
      }
    }
  }
}
