@import '../../../variables.scss';

#modalContent {
  * {
    margin-bottom: 5px;
  }
  .btnAction {
    * {
      color: $white;
    }
    display: flex;
    margin: auto;
    width: 50%;
    padding: 5px;
    border-radius: 5px;
    background-color: $black;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
