@import '../../../variables';

.custom-MUI-input {
  &.MuiTextField-root {
    .MuiFilledInput-root:after,
    .MuiInput-root:after {
      border-color: $black;
    }
    .MuiInputLabel-shrink {
      color: $black;
    }
  }

  @media (max-width: $breakpoint) {
  }
}
